import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './App.Routes';
import ScrollToTop from './App.Scroll';

import { ChakraProvider as Theme } from '@chakra-ui/react';
import { theme } from './styles/theme';
import { Global, css } from '@emotion/react';

const GlobalStyles = css`
/*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
*/
    .js-focus-visible :focus:not([data-focus-visible-added]) {
        outline: none;
        box-shadow: none;
    }
`;

const Root = () =>
(
	<BrowserRouter>        
        <Theme theme={theme}>
            <Global styles={GlobalStyles} />
            <ScrollToTop />
            <App />
        </Theme>        
    </BrowserRouter>
);

export default Root;