import { FullCenterContainer } from '../../common/layouts';
import { Box, VStack, Footer, Text } from '../../common/components';
import ListItem from './components/ListItem';

const Main = () =>
(
	<FullCenterContainer
	footer={<Footer full />}>
		<Box w="100%" textAlign="center" py="40px" px="20px">
			<VStack spacing="0px">
				<Text fontSize="30px" fontWeight="800">We are moleQlar.</Text>
				<Text fontSize="16px">Independent game studio. 50% Fede & 50% Isra.</Text>
			</VStack>
			<VStack mt="40px">
				<Text fontSize="20px">Games we're working on:</Text>
				<ListItem text="Bubble Shooter Cookie"
					android="https://play.google.com/store/apps/details?id=bubble.shooter.pop.shoot.puzzle.cookie" />
				<ListItem text="Puzzle Stars"
					android="https://play.google.com/store/apps/details?id=moleqlar.jigsaw.puzzles.hd.collections" />
			</VStack>
			<VStack mt="40px">
				<Text fontSize="18px">Games we made:</Text>
				<ListItem text="Tiny Wild West" />
				<ListItem text="Pokemoji Go" />
				<ListItem text="Happy Unicorn" />
				<ListItem text="Zombi Candy" />
				<ListItem text="Bouncy Food" />
			</VStack>
			<Text fontSize="16px" mt="50px">
			For press, business inquiries or support, email <a href="mailto:isra@moleqlargames.com?cc=fede@moleqlargames.com">us</a>.
			</Text>
		</Box>
	</FullCenterContainer>
);

export default Main;
