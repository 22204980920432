import { Box, Flex, Spacer, Text, LinkButton, HStack } from './index';
import links from '../links';

const Header = () =>
(
    <Box
    bg="white"
    pos="fixed" top={0} zIndex={1000}
    borderBottom="1px" borderBottomColor="#E7ECF9"
    w="100%" h="73px">
        <Flex px="20px" flexDirection="row" maxW="1216px" mx="auto" h="100%">
            <HStack spacing="20px">
                <LinkButton to={links.home}>Home</LinkButton>
                <LinkButton to={links.blog} isExternal>Blog</LinkButton>
            </HStack>
            <Spacer />
            <Flex align="center">
                <Text fontWeight="800">We are moleQlar.</Text>
            </Flex>
        </Flex>
    </Box>
);

export default Header;