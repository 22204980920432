const Cookies = () =>
(
    <div style={{ marginTop: '10vh', marginBottom: '5vh', maxWidth: '80vw', marginLeft: 'auto', marginRight: 'auto', color: '#000000', textAlign: 'left' }}>
        <div style={{fontSize:"30px"}}>
            <center>COOKIES POLICY<br/>moleQlar games</center>
        </div>
        <div style={{marginTop:"40px", marginBottom:"40px"}}>
        En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
        Información y Comercio Electrónico informamos al Usuario de la utilización de cookies
        en la web de moleQlar games.<br/><br/>
        Las cookies permiten, entre otras cosas, recopilar información estadística, facilitar
        ciertas funcionalidades técnicas, almacenar y recuperar información sobre los hábitos
        de navegación o preferencias de un Usuario o de su equipo y, dependiendo de la
        información que contengan y de la forma en que utilice su equipo, pueden utilizarse
        para reconocer al Usuario, recordar aspectos del Usuario como su idioma, país,
        navegador, etc. En caso de no querer recibir cookies, el Usuario podrá configurar su
        navegador para que las borre del disco duro de su ordenador, las bloquee o le avise en
        su caso de instalación de las mismas.<br/><br/>
        Nuestro sitio web utiliza cookies y otras tecnologías relacionadas (para mayor
        comodidad, todas las tecnologías se denominan “cookies”). Las cookies también son
        colocadas por terceros a los que hemos contratado. En el siguiente documento te
        informamos sobre el uso de cookies en nuestra web.
        </div>
        <br/>
        <p style={{fontSize:"24px", marginBottom:"40px"}}><strong>
        ¿Qué son las cookies, los scripts o las balizas web?
        </strong></p>
        Una cookie es un pequeño archivo simple que se envía junto con las páginas de esta
        web y que el navegador del usuario almacena en el disco duro de su ordenador o de
        otro dispositivo (smartphone, tablet, televisión conectada…). La información
        almacenada puede ser devuelta a nuestros servidores o a los servidores de terceros
        relevantes durante una visita posterior.<br/><br/>
        Un script es un fragmento de código de programa que se utiliza para hacer que nuestra
        web funcione correctamente y de forma interactiva. Este código se ejecuta en nuestro
        servidor o en el dispositivo del usuario.<br/><br/>
        Una baliza web (o una etiqueta de píxel) es una pequeña e invisible pieza de texto o
        imagen en una web que se utiliza para hacer seguimiento del tráfico en una web.
        <br/><br/><br/>
        <p style={{fontSize:"24px", marginBottom:"40px"}}><strong>
        Cookies
        </strong></p>
        <strong>• Cookies técnicas o funcionales</strong><br/><br/>
        Algunas cookies aseguran que ciertas partes de la web funcionen correctamente y que
        las preferencias de usuario sigan recordándose. Al colocar cookies funcionales,
        facilitamos la visita y uso de nuestra web. De esta manera, no es necesario introducir
        repetidamente la misma información cuando se visita nuestra web y, por ejemplo, los
        artículos permanecen en la cesta de la compra hasta que el usuario finalice la compra.
        La normativa nos permite colocar estas cookies sin tu consentimiento.
        <br/><br/>
        <strong>• Cookies analíticas</strong><br/><br/>
        Usamos cookies analíticas para optimizar la experiencia en el sitio web de nuestros
        usuarios. Con estas cookies analíticas obtenemos conocimientos del uso de nuestro
        sitio web. Es necesario el consentimiento del usuario para insertar cookies analíticas.
        <br/><br/>
        <strong>• Cookies de publicidad o marketing</strong><br/><br/>
        En esta web utilizamos cookies publicitarias, lo que nos permite personalizar los
        anuncios para cada usuario, y nosotros (y terceros) obtenemos información sobre los
        resultados de la campaña. Esto sucede en base a un perfil que creamos con los clics de
        los usuarios y navegación dentro y fuera de “www.moleqlargames.com. Con estas
        cookies el usuario, como visitante de la web, está vinculado a un ID único. Es necesario
        el consentimiento del usuario para insertar estas cookies.
        <br/><br/>
        <strong>• Botones de medios sociales</strong><br/><br/>
        En nuestra web hemos incluidos botones para promocionar páginas (p. ej. «me gusta»,
        «pin») o compartirlas (p. ej. «tuitear») en redes sociales como Facebook. Estos botones
        funcionan usando código de estas RRSS; este código incrusta cookies. Estos botones de
        redes sociales pueden almacenar y procesar cierta información, de esta manera puede
        ser mostrado un anuncio personalizado.
        <br/><br/><br/>
        <p style={{fontSize:"24px", marginBottom:"40px"}}><strong>
        Cookies instaladas por terceros
        </strong></p>
        El Sitio Web puede utilizar cookies instaladas por terceros para realizar un análisis
        estadístico sobre la navegación de los usuarios, o mostrar contenido publicitario
        personalizado. Por ejemplo, cookies de Google. Tenga en cuenta que, si acepta las
        cookies de terceros, deberá eliminarlas desde las opciones del navegador o desde el
        sistema ofrecido por el propio tercero
        <br/><br/><br/>
        <p style={{fontSize:"24px", marginBottom:"40px"}}><strong>
        Tus derechos con respecto a los datos personales
        </strong></p>
        Visita nuestra política de privacidad para conocer tus derechos con respecto al
        tratamiento de datos personales, y cómo ejercerlos.
        <br/><br/><br/>
        <p style={{fontSize:"24px", marginBottom:"40px"}}><strong>
        Activación, desactivación y eliminación de cookies
        </strong></p>
        Puedes utilizar tu navegador de Internet para eliminar las cookies de forma automática
        o manual. También puedes especificar que ciertas cookies no pueden ser colocadas.
        Otra opción es cambiar los ajustes de tu navegador de Internet para que recibas un
        mensaje cada vez que se coloca una cookie. Para obtener más información sobre estas
        opciones, consulta las instrucciones de la sección de «Ayuda» de tu navegador.
        Ten en cuenta que nuestra web puede no funcionar correctamente si todas las cookies
        están desactivadas. Si borras las cookies de tu navegador, se volverán a colocar
        después de tu consentimiento cuando vuelvas a visitar nuestras webs
        <br/><br/><br/>
    </div>
);

export default Cookies;