import { Link } from './index';
import { Link as ReactRouter } from 'react-router-dom';

const ExtenalLink = ({ id, children, to }) =>
(
    <Link
    id={id}
    fontSize="14px"
    fontWeight="500"
    color="black"
    href={to}
    isExternal
    >
    {children}
    </Link> 
);

const LocalLink = ({id, children, to }) =>
(
    <Link as={ReactRouter}
    id={id}
    fontSize="14px"
    fontWeight="500"
    color="black"
    to={to}
    >
    {children}
    </Link>
);

const LinkButton = ({ id, children, to, isExternal }) =>
{
    if (isExternal) return <ExtenalLink id={id} to={to}>{children}</ExtenalLink>
    return <LocalLink id={id} to={to}>{children}</LocalLink>
}

export default LinkButton;
