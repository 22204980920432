import { Image } from './index';
import logo from '../../styles/assets/logo.png';

const MobileLogo = () =>
(
    <img src={logo} className="App-logo-mobile" alt="moleQlar games logo" />
);

const DesktopLogo = () =>
(
    <img src={logo} className="App-logo-desktop" alt="moleQlar games logo" />
);

const PlainLogo = ({ size = '100px' }) =>
(
    <Image 
    boxSize={size}
    objectFit="contain"
    src={logo}
    alt="moleQlar games logo" />
);

const Logo = ({ variant, size }) =>
{
    switch (variant) {
        case 'mobile': return <MobileLogo />;
        case 'desktop': return <DesktopLogo />;
        default: return <PlainLogo size={size} />;
    }
};

export default Logo;