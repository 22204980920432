import { Center, Flex } from '../components';

const FullContainer = ({ header, children, footer }) =>
(
    <div>
    {header ? header : null}
    <Flex maxW="1216px" minH="100vh" direction="column" style={{ margin: 'auto' }}>
        <Center flex="1">{children}</Center>
        {footer ? footer : null}
    </Flex>
    </div>
);

export default FullContainer;