import { FullCenterContainer } from '../../common/layouts';
import { Box, Footer, Header } from '../../common/components';
import Opa from './components/Game';

const Game = () =>
(
    <FullCenterContainer
    header={<Header />}
    footer={<Footer />}>
        <Box w="100%" textAlign="center">
            <Opa />
        </Box>
    </FullCenterContainer>
);

export default Game;