const Privacy = () =>
(
    <div style={{ marginTop: '10vh', marginBottom: '5vh', maxWidth: '80vw', marginLeft: 'auto', marginRight: 'auto', color: '#000000', textAlign: 'left' }}>
    <p class="bg">
      <div style={{fontSize:"30px"}}>
        <center>
          PRIVACY POLICY<br/>moleQlar games
        </center>
      </div>
      <div style={{marginTop:"40px", marginBottom:"40px"}}>
      This privacy policy governs your use of the software application of this App for mobile devices that was created by our company.
      </div>
      <br/>
      <p style={{fontSize:"24px", marginBottom:"40px"}}><strong>
      What information does the Application obtain and how is it used?
      </strong></p>
      <strong>• Automatically Collected Information</strong><br/><br/>
      The Application may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique device ID, the IP address of your mobile device, your mobile operating system and information about the way you use the Application.
      <br/><br/><br/>
      <p style={{fontSize:"24px", marginBottom:"40px"}}><strong>
      Automatic Data Collection and Advertising
      </strong></p>
      We use device identifiers to personalise content and ads, to provide social media features and to analyse our traffic. We also share such identifiers and other information from your device with our social media, advertising and analytics partners.
      <br/><br/>
      <strong>• Advertising</strong><br/><br/>
      This type of service allows User Data to be utilized for advertising communication purposes displayed in the form of banners and other advertisements on this Application, possibly based on User interests.
      <br/>
      This does not mean that all Personal Data are used for this purpose. Information and conditions of use are shown below.
      <br/>
      Some of the services listed below may use Cookies to identify Users or they may use the behavioral retargeting technique, i.e. displaying ads tailored to the User’s interests and behavior, including those detected outside this Application. For more information, please check the privacy policies of the relevant services:
      <br/><br/>
      <ul>
        <li style={{ marginLeft: "40px" }}>
          <strong>Admob:</strong><br/><br/>
          AdMob is an advertising service provided by AdMob Google Inc. In order to understand Google's use of Data, consult <a href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noreferrer">Google's partner policy</a>.<br/><br/>Google also shares these data with commonly used <a href="https://support.google.com/admob/answer/7666519#providers" target="_blank" rel="noreferrer">providers</a>:<br/><br/>
        </li>
      </ul>
      <div class="table-responsive">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td><center><a href="https://www.google.com/policies/technologies/partner-sites/" target="_blank" rel="noreferrer">Google *</a></center></td>
            <td><center><a href="https://liftoff.io/privacy-policy/" target="_blank" rel="noreferrer">Liftoff *</a></center></td>
            <td><center><a href="https://www.conversantmedia.eu/legal/privacy-policy" target="_blank" rel="noreferrer">Conversant/CJ Affiliate *</a></center></td>
            <td><center><a href="https://relay42.com/privacy" target="_blank" rel="noreferrer">Relay42 *</a></center></td>
            <td><center><a href="http://www.lifestreet.com/privacy" target="_blank" rel="noreferrer">LifeStreet *</a></center></td>
          </tr>
          <tr>
            <td><center><a href="http://corp.aarki.com/privacy" target="_blank" rel="noreferrer">Aarki *</a></center></td>
            <td><center><a href="https://liveramp.com/service-privacy-policy/" target="_blank" rel="noreferrer">LiveRamp *</a></center></td>
            <td><center><a href="https://crimtan.com/privacy-ctl/" target="_blank" rel="noreferrer">Crimtan *</a></center></td>
            <td><center><a href="http://remerge.io/privacy-policy.html" target="_blank" rel="noreferrer">Remerge *</a></center></td>
            <td><center><a href="https://www.widespace.com/legal/privacy-policy-notice/" target="_blank" rel="noreferrer">Widespace *</a></center></td>
          </tr>
          <tr>
            <td><center><a href="https://www.adacado.com/privacy-policy-april-25-2018/" target="_blank" rel="noreferrer">Adacado *</a></center></td>
            <td><center><a href="https://www.localsensor.com/privacy.html" target="_blank" rel="noreferrer">Localsensor *</a></center></td>
            <td><center><a href="https://www.criteo.com/privacy/" target="_blank" rel="noreferrer">Criteo *</a></center></td>
            <td><center><a href="https://www.resonate.com/privacy-policy/" target="_blank" rel="noreferrer">Resonate *</a></center></td>
            <td><center><a href="https://www.yieldr.com/privacy/" target="_blank" rel="noreferrer">Yieldr *</a></center></td>
          </tr>
          <tr>
            <td><center><a href="https://adara.com/2018/04/10/adara-gdpr-faq/" target="_blank" rel="noreferrer">Adara Media *</a></center></td>
            <td><center><a href="https://loopme.com/privacy/" target="_blank" rel="noreferrer">LoopMe *</a></center></td>
            <td><center><a href="http://cubed.ai/privacy-policy/gdpr/" target="_blank" rel="noreferrer">CUBED *</a></center></td>
            <td><center><a href="https://www.revjet.com/privacy" target="_blank" rel="noreferrer">RevJet *</a></center></td>
            <td><center><a href="https://zms.zalando.com" target="_blank" rel="noreferrer">ZMS *</a></center></td>
          </tr>
          <tr>
            <td><center><a href="https://www.adclear.de/datenschutzerklaerung/" target="_blank" rel="noreferrer">AdClear *</a></center></td>
            <td><center><a href="https://www.lotame.com/about-lotame/privacy/" target="_blank" rel="noreferrer">Lotame *</a></center></td>
            <td><center><a href="https://www.dataxu.com/gdpr/" target="_blank" rel="noreferrer">DataXu *</a></center></td>
            <td><center><a href="https://www.rhythmone.com/landing/gdpr" target="_blank" rel="noreferrer">RhythmOne LLC *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="http://theadex.com/" target="_blank" rel="noreferrer">ADEX *</a></center></td>
            <td><center><a href="https://www.metrixlab.com/privacy-statement/" target="_blank" rel="noreferrer">Macromill group *</a></center></td>
            <td><center><a href="http://www.deltaprojects.com/data-collection-policy/" target="_blank" rel="noreferrer">Delta Projects *</a></center></td>
            <td><center><a href="https://www.valuedopinions.co.uk/privacy" target="_blank" rel="noreferrer">RN SSI Group *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://site.adform.com/uncategorized/product-and-services-privacy-policy/" target="_blank" rel="noreferrer">Adform *</a></center></td>
            <td><center><a href="http://www.mainad.com/privacy-policy" target="_blank" rel="noreferrer">MainADV *</a></center></td>
            <td><center><a href="https://www.demandbase.com/privacy-policy/" target="_blank" rel="noreferrer">Demandbase *</a></center></td>
            <td><center><a href="https://www.roq.ad/privacy-policy" target="_blank" rel="noreferrer">Roq.ad *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.adikteev.com/eu/privacy/" target="_blank" rel="noreferrer">Adikteev *</a></center></td>
            <td><center><a href="https://www.manage.com/privacy-policy/" target="_blank" rel="noreferrer">Manage.com *</a></center></td>
            <td><center><a href="http://www.dentsu.co.jp/terms/data_policy.html" target="_blank" rel="noreferrer">DENTSU *</a></center></td>
            <td><center><a href="https://www.rtbhouse.com/gdpr/" target="_blank" rel="noreferrer">RTB House *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://adledge.com/data-privacy/" target="_blank" rel="noreferrer">AdLedge *</a></center></td>
            <td><center><a href="http://mktsci.com/privacy_policy.htm" target="_blank" rel="noreferrer">Marketing Science Consulting Group, Inc. *</a></center></td>
            <td><center><a href="http://www.dentsu.com/termsofuse/data_policy.html" target="_blank" rel="noreferrer">Dentsu Aegis Network *</a></center></td>
            <td><center><a href="http://rubiconproject.com/rubicon-project-yield-optimization-privacy-policy/" target="_blank" rel="noreferrer">Rubicon Project *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="http://adloox.com/disclaimer" target="_blank" rel="noreferrer">Adloox *</a></center></td>
            <td><center><a href="https://privacy.mbr-targeting.com/" target="_blank" rel="noreferrer">MBR Targeting Gmbh *</a></center></td>
            <td><center><a href="http://www.digiseg.io/GDPR/" target="_blank" rel="noreferrer">Digiseg *</a></center></td>
            <td><center><a href="https://www.salesforce.com/company/privacy/" target="_blank" rel="noreferrer">Salesforce DMP *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://adludio.com/termsandconditions" target="_blank" rel="noreferrer">Adludio *</a></center></td>
            <td><center><a href="http://www.mediamath.com/privacy-policy/" target="_blank" rel="noreferrer">MediaMath *</a></center></td>
            <td><center><a href="https://www.dma-institute.com/privacy-compliancy/" target="_blank" rel="noreferrer">DMA Institute *</a></center></td>
            <td><center><a href="http://www.scenestealer.tv/privacy-policy" target="_blank" rel="noreferrer">Scenestealer *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="http://www.admaxim.com/admaxim-privacy-policy/" target="_blank" rel="noreferrer">AdMaxim *</a></center></td>
            <td><center><a href="https://www.meetrics.com/en/data-privacy/" target="_blank" rel="noreferrer">Meetrics *</a></center></td>
            <td><center><a href="https://www.doubleverify.com/privacy/" target="_blank" rel="noreferrer">DoubleVerify *</a></center></td>
            <td><center><a href="http://scoota.com/privacy-policy/" target="_blank" rel="noreferrer">Scoota *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.admedo.com/privacy-policy" target="_blank" rel="noreferrer">Admedo *</a></center></td>
            <td><center><a href="https://www.mindtake.com/en/reppublika-privacy-policy" target="_blank" rel="noreferrer">MindTake Research *</a></center></td>
            <td><center><a href="https://dstillery.com/privacy-policy/" target="_blank" rel="noreferrer">Dstillery *</a></center></td>
            <td><center><a href="https://seenthis.co/privacy-notice-2018-04-18.pdf" target="_blank" rel="noreferrer">Seenthis *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://admetrics.io/en/privacy_policy/" target="_blank" rel="noreferrer">Admetrics *</a></center></td>
            <td><center><a href="http://www.mobitrans.net/privacy-policy/" target="_blank" rel="noreferrer">Mobitrans *</a></center></td>
            <td><center><a href="https://client.adhslx.com/privacy.html" target="_blank" rel="noreferrer">DTS *</a></center></td>
            <td><center><a href="http://www.semasio.com/privacy-policy/" target="_blank" rel="noreferrer">Semasio GmbH *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.adobe.com/privacy/general-data-protection-regulation.html" target="_blank" rel="noreferrer">Adobe Advertising Cloud *</a></center></td>
            <td><center><a href="http://mobpro.com/privacy.html" target="_blank" rel="noreferrer">Mobpro *</a></center></td>
            <td><center><a href="https://www.rvty.net/gdpr" target="_blank" rel="noreferrer">EASYmedia *</a></center></td>
            <td><center><a href="http://www.sfr.fr/securite-confidentialite.html" target="_blank" rel="noreferrer">SFR *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.adroll.com/gdpr" target="_blank" rel="noreferrer">AdRoll, Inc. *</a></center></td>
            <td><center><a href="http://www.molocoads.com/private-policy.html" target="_blank" rel="noreferrer">Moloco Ads *</a></center></td>
            <td><center><a href="https://www.ebay.com/help/policies/member-behaviour-policies/user-privacy-notice-privacy-policy?id=4260#section12" target="_blank" rel="noreferrer">eBay *</a></center></td>
            <td><center><a href="https://www.sift.co/privacy" target="_blank" rel="noreferrer">Sift Media *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://privacy.adtriba.com/" target="_blank" rel="noreferrer">AdTriba *</a></center></td>
            <td><center><a href="http://site.msi-aci.com/Home/FlexTrackPrivacy" target="_blank" rel="noreferrer">MSI-ACI *</a></center></td>
            <td><center><a href="http://ebuilders.nl/privacy" target="_blank" rel="noreferrer">ebuilders *</a></center></td>
            <td><center><a href="https://www.simpli.fi/site-privacy-policy2/" target="_blank" rel="noreferrer">Simpli.fi *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.ad4mat.com/en/privacy/" target="_blank" rel="noreferrer">advanced STORE GmbH *</a></center></td>
            <td><center><a href="http://www.nanointeractive.com/privacy" target="_blank" rel="noreferrer">Nano Interactive *</a></center></td>
            <td><center><a href="https://www.effiliation.com/politique-confidentialite.html" target="_blank" rel="noreferrer">Effinity *</a></center></td>
            <td><center><a href="https://www.sizmek.com/privacy-policy/" target="_blank" rel="noreferrer">Sizmek *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.adventori.com/fr/avec-nous/mentions-legales/" target="_blank" rel="noreferrer">Adventori *</a></center></td>
            <td><center><a href="https://www.navegg.com/en/privacy-policy/" target="_blank" rel="noreferrer">Navegg *</a></center></td>
            <td><center><a href="https://www.emetriq.com/datenschutz/" target="_blank" rel="noreferrer">emetriq *</a></center></td>
            <td><center><a href="https://www.smaato.com/privacy/" target="_blank" rel="noreferrer">Smaato *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="http://advolution.de/privacy.php" target="_blank" rel="noreferrer">advolution.control *</a></center></td>
            <td><center><a href="https://www.neodatagroup.com/en/security-policy" target="_blank" rel="noreferrer">Neodata Group *</a></center></td>
            <td><center><a href="https://www.ensighten.com/privacy-policy/" target="_blank" rel="noreferrer">Ensighten *</a></center></td>
            <td><center><a href="http://smadex.com/end-user-privacy-policy/" target="_blank" rel="noreferrer">Smadex *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.affili.net/uk/footeritem/privacy-policy" target="_blank" rel="noreferrer">affilinet *</a></center></td>
            <td><center><a href="https://www.neory.com/privacy.html" target="_blank" rel="noreferrer">NEORY GmbH *</a></center></td>
            <td><center><a href="https://essens.no/privacy-policy/" target="_blank" rel="noreferrer">Essens *</a></center></td>
            <td><center><a href="http://smartadserver.com/company/privacy-policy/" target="_blank" rel="noreferrer">Smart *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="http://www.akamai.com/compliance/privacy" target="_blank" rel="noreferrer">Akamai *</a></center></td>
            <td><center><a href="http://www.netflix.com/google-3PAS-info" target="_blank" rel="noreferrer">Netflix *</a></center></td>
            <td><center><a href="https://www.wizaly.com/terms-of-use#privacy-policy" target="_blank" rel="noreferrer">ESV *</a></center></td>
            <td><center><a href="https://www.smartology.net/privacy-policy/" target="_blank" rel="noreferrer">Smartology *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.amazon.co.uk/gp/help/customer/display.html?nodeId=201909010" target="_blank" rel="noreferrer">Amazon *</a></center></td>
            <td><center><a href="https://www.nicequest.com/us/privacy" target="_blank" rel="noreferrer">Netquest *</a></center></td>
            <td><center><a href="https://www.evidon.com/resources/privacy-statement/" target="_blank" rel="noreferrer">Evidon *</a></center></td>
            <td><center><a href="https://www.sociomantic.com/privacy/en/" target="_blank" rel="noreferrer">Sociomantic *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.amobee.com/trust/privacy-guidelines" target="_blank" rel="noreferrer">Amobee *</a></center></td>
            <td><center><a href="https://web.neural.one/privacy-policy/" target="_blank" rel="noreferrer">Neuralone *</a></center></td>
            <td><center><a href="https://www.exactag.com/en/data-privacy/" target="_blank" rel="noreferrer">Exactag *</a></center></td>
            <td><center><a href="https://www.sojern.com/privacy/product-privacy-policy/" target="_blank" rel="noreferrer">Sojern *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://analights.com/docs/analights-consent-to-the-processing-of-personal-data-en.pdf" target="_blank" rel="noreferrer">Analights *</a></center></td>
            <td><center><a href="https://www.home.neustar/privacy" target="_blank" rel="noreferrer">Neustar *</a></center></td>
            <td><center><a href="http://exponential.com/privacy/" target="_blank" rel="noreferrer">Exponential *</a></center></td>
            <td><center><a href="https://www.sovrn.com/privacy-policy/" target="_blank" rel="noreferrer">Sovrn *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.applovin.com/privacy" target="_blank" rel="noreferrer">AppLovin Corp. *</a></center></td>
            <td><center><a href="http://www.nielsen.com/us/en/privacy-statement/digital-measurement.html" target="_blank" rel="noreferrer">Nielsen *</a></center></td>
            <td><center><a href="https://www.facebook.com/about/privacy/update" target="_blank" rel="noreferrer">Facebook *</a></center></td>
            <td><center><a href="http://www.spotad.co/privacy-policy/" target="_blank" rel="noreferrer">Spotad *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.appnexus.com/en/company/platform-privacy-policy" target="_blank" rel="noreferrer">AppNexus *</a></center></td>
            <td><center><a href="https://www.bannerflow.com/privacy" target="_blank" rel="noreferrer">Nordic Factory Solutions AB *</a></center></td>
            <td><center><a href="http://www.flashtalking.com/first-party-ad-serving/" target="_blank" rel="noreferrer">Flashtalking *</a></center></td>
            <td><center><a href="https://www.spotx.tv/privacy-policy/" target="_blank" rel="noreferrer">SpotX *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.arrivalist.com/privacy#gdpr" target="_blank" rel="noreferrer">Arrivalist *</a></center></td>
            <td><center><a href="http://ads.1000mercis.com/us.html" target="_blank" rel="noreferrer">numberly *</a></center></td>
            <td><center><a href="https://www.fractionalmedia.com/privacy-policy" target="_blank" rel="noreferrer">Fractional Media *</a></center></td>
            <td><center><a href="http://static.tabmo.io.s3.amazonaws.com/privacy-policy/index.html" target="_blank" rel="noreferrer">TabMo *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="http://privacy.audienceproject.com/for-users/" target="_blank" rel="noreferrer">AudienceProject *</a></center></td>
            <td><center><a href="https://policies.oath.com/xw/en/oath/privacy/enterprise/b2bprivacypolicy/" target="_blank" rel="noreferrer">Oath *</a></center></td>
            <td><center><a href="http://www.s4m.io/privacy-policy/" target="_blank" rel="noreferrer">FUSIO BY S4M *</a></center></td>
            <td><center><a href="https://www.taboola.com/privacy-policy" target="_blank" rel="noreferrer">Taboola *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="http://www.aunica.com/privacy/privacy.html" target="_blank" rel="noreferrer">Aunica *</a></center></td>
            <td><center><a href="https://www.objectivepartners.com/cookie-policy-and-privacy-statement/" target="_blank" rel="noreferrer">Objective Partners *</a></center></td>
            <td><center><a href="https://www.gemius.com/cookie-policy.html" target="_blank" rel="noreferrer">Gemius *</a></center></td>
            <td><center><a href="http://tacticrealtime.com/privacy/" target="_blank" rel="noreferrer">TACTIC™ Real-Time Marketing *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="http://avocet.io/privacy-portal" target="_blank" rel="noreferrer">Avocet *</a></center></td>
            <td><center><a href="https://www.omnicommediagroup.com/disclaimer.htm" target="_blank" rel="noreferrer">Omnicom Media Group *</a></center></td>
            <td><center><a href="https://sensic.net/" target="_blank" rel="noreferrer">GfK *</a></center></td>
            <td><center><a href="https://teads.tv/privacy-policy/" target="_blank" rel="noreferrer">Teads.TV *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="http://batch.ba/datenschutzerklarung/" target="_blank" rel="noreferrer">Batch Media *</a></center></td>
            <td><center><a href="https://ondeviceresearch.com/GDPR" target="_blank" rel="noreferrer">On Device Research *</a></center></td>
            <td><center><a href="http://www.gsi-one.org/templates/gsi/en/files/privacy_policy.pdf" target="_blank" rel="noreferrer">GP One *</a></center></td>
            <td><center><a href="https://teemo.co/fr/confidentialite/" target="_blank" rel="noreferrer">TEEMO *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.xxxlutz.de/ocms/privacy" target="_blank" rel="noreferrer">BDSK Handels GmbH & Co. KG *</a></center></td>
            <td><center><a href="https://www.onetag.net/privacy/" target="_blank" rel="noreferrer">OneTag *</a></center></td>
            <td><center><a href="https://www.groupm.com/privacy-policy" target="_blank" rel="noreferrer">GroupM *</a></center></td>
            <td><center><a href="https://www.thetradedesk.com/general/privacy-policy" target="_blank" rel="noreferrer">The Trade Desk *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.beeswax.com/privacy.html" target="_blank" rel="noreferrer">Beeswax *</a></center></td>
            <td><center><a href="https://www.openx.com/legal/privacy-policy/" target="_blank" rel="noreferrer">OpenX Technologies *</a></center></td>
            <td><center><a href="https://createjs.com/legal/privacy.html" target="_blank" rel="noreferrer">gskinner *</a></center></td>
            <td><center><a href="http://www.tradedoubler.com/en/privacy-policy/" target="_blank" rel="noreferrer">Tradedoubler *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="http://betting.geniussports.com/connextra-service-terms-conditions/" target="_blank" rel="noreferrer">Betgenius *</a></center></td>
            <td><center><a href="http://optomaton.com/privacy.html" target="_blank" rel="noreferrer">Optomaton *</a></center></td>
            <td><center><a href="https://haensel-ams.com/data-privacy/" target="_blank" rel="noreferrer">Haensel AMS *</a></center></td>
            <td><center><a href="https://travelaudience.com/product-privacy-policy/" target="_blank" rel="noreferrer">travel audience – An Amadeus Company *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="http://www.bidswitch.com/privacy-policy/" target="_blank" rel="noreferrer">Bidswitch *</a></center></td>
            <td><center><a href="https://www.oracle.com/legal/privacy/marketing-cloud-data-cloud-privacy-policy.html" target="_blank" rel="noreferrer">Oracle Data Cloud *</a></center></td>
            <td><center><a href="http://www.centraltag.com/commercial/policy/index.action" target="_blank" rel="noreferrer">Havas Media France - DBi *</a></center></td>
            <td><center><a href="http://tresensa.com/eu-privacy/index.html" target="_blank" rel="noreferrer">TreSensa *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="http://www.blis.com/privacy/" target="_blank" rel="noreferrer">Blismedia *</a></center></td>
            <td><center><a href="https://www.otto.de/shoppages/service/datenschutz" target="_blank" rel="noreferrer">OTTO *</a></center></td>
            <td><center><a href="http://www.hurra.com/impressum" target="_blank" rel="noreferrer">hurra.com *</a></center></td>
            <td><center><a href="https://triplelift.com/privacy/" target="_blank" rel="noreferrer">TripleLift *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="http://bombora.com/privacy" target="_blank" rel="noreferrer">Bombora *</a></center></td>
            <td><center><a href="http://permodo.com/de/privacy.html" target="_blank" rel="noreferrer">PERMODO *</a></center></td>
            <td><center><a href="https://www.ibm.com/customer-engagement/digital-marketing/gdpr" target="_blank" rel="noreferrer">IBM *</a></center></td>
            <td><center><a href="https://trueffect.com/privacy-policy/" target="_blank" rel="noreferrer">TruEffect *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.booking.com/content/privacy.en-gb.html" target="_blank" rel="noreferrer">Booking.com *</a></center></td>
            <td><center><a href="http://www.pixalate.com/privacypolicy/" target="_blank" rel="noreferrer">Pixalate *</a></center></td>
            <td><center><a href="https://www.ignitionone.com/privacy-policy/gdpr-subject-access-requests/" target="_blank" rel="noreferrer">Ignition One *</a></center></td>
            <td><center><a href="https://www.trustarc.com/privacy-policy/" target="_blank" rel="noreferrer">TrustArc *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://c3metrics.com/privacy" target="_blank" rel="noreferrer">C3 Metrics *</a></center></td>
            <td><center><a href="https://platform161.com/cookie-and-privacy-policy/" target="_blank" rel="noreferrer">Platform161 *</a></center></td>
            <td><center><a href="https://impact.com/privacy-policy/" target="_blank" rel="noreferrer">Impact *</a></center></td>
            <td><center><a href="https://www.turbobytes.com/gdpr/" target="_blank" rel="noreferrer">Turbobytes *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="http://cablato.com/privacy" target="_blank" rel="noreferrer">Cablato *</a></center></td>
            <td><center><a href="https://www.pmg.com/privacy-policy/" target="_blank" rel="noreferrer">PMG *</a></center></td>
            <td><center><a href="http://www.indexexchange.com/privacy/" target="_blank" rel="noreferrer">Index Exchange *</a></center></td>
            <td><center><a href="https://www.udg.de/wp-content/uploads/2018/04/privacy_policy_EN.pdf" target="_blank" rel="noreferrer">UDG *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.celtra.com/privacy-policy/" target="_blank" rel="noreferrer">Celtra *</a></center></td>
            <td><center><a href="http://www.protected.media/privacy-policy/" target="_blank" rel="noreferrer">Protected Media *</a></center></td>
            <td><center><a href="https://impressiondesk.com/" target="_blank" rel="noreferrer">Infectious Media *</a></center></td>
            <td><center><a href="http://www.usemax.de/?l=privacy" target="_blank" rel="noreferrer">usemax (Emego GmbH) *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.centro.net/privacy-policy/" target="_blank" rel="noreferrer">Centro *</a></center></td>
            <td><center><a href="https://www.publicismedia.de/datenschutz/" target="_blank" rel="noreferrer">Publicis Groupe *</a></center></td>
            <td><center><a href="http://www.innovid.com/privacy-policy" target="_blank" rel="noreferrer">Innovid *</a></center></td>
            <td><center><a href="https://videologygroup.com/en/privacy-policy/" target="_blank" rel="noreferrer">Videology *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.cint.com/participant-privacy-notice/" target="_blank" rel="noreferrer">Cint *</a></center></td>
            <td><center><a href="https://pubmatic.com/legal/privacy-policy/" target="_blank" rel="noreferrer">PubMatic *</a></center></td>
            <td><center><a href="http://www.integralads.com/privacy-policy" target="_blank" rel="noreferrer">Integral Ad Science *</a></center></td>
            <td><center><a href="https://vimeo.com/cookie_policy" target="_blank" rel="noreferrer">Vimeo *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://clinch.co/pages/privacy.html" target="_blank" rel="noreferrer">Clinch *</a></center></td>
            <td><center><a href="https://www.pulsepoint.com/privacy-policy" target="_blank" rel="noreferrer">PulsePoint *</a></center></td>
            <td><center><a href="https://www.intelliad.de/datenschutz" target="_blank" rel="noreferrer">intelliAd *</a></center></td>
            <td><center><a href="https://virtualminds.de/datenschutz/" target="_blank" rel="noreferrer">Virtual Minds *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="http://green.erne.co/assets/PolicyCT.pdf" target="_blank" rel="noreferrer">Cloud Technologies *</a></center></td>
            <td><center><a href="https://www.quantcast.com/privacy/" target="_blank" rel="noreferrer">Quantcast *</a></center></td>
            <td><center><a href="https://www.interpublic.com/privacy-notice" target="_blank" rel="noreferrer">Interpublic Group *</a></center></td>
            <td><center><a href="https://www.vodafone.de/unternehmen/datenschutz-privatsphaere.html" target="_blank" rel="noreferrer">Vodafone GmbH *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.cloudflare.com/security-policy/" target="_blank" rel="noreferrer">Cloudflare *</a></center></td>
            <td><center><a href="http://www.rackspace.com/gdpr" target="_blank" rel="noreferrer">Rackspace *</a></center></td>
            <td><center><a href="http://www.jivox.com/privacy/" target="_blank" rel="noreferrer">Jivox *</a></center></td>
            <td><center><a href="https://www.wayfair.com/customerservice/general_info.php#privacy" target="_blank" rel="noreferrer">Waystack *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.commandersact.com/en/privacy/" target="_blank" rel="noreferrer">Commanders Act *</a></center></td>
            <td><center><a href="https://rakutenmarketing.com/legal-notices/services-privacy-policy" target="_blank" rel="noreferrer">Rakuten Marketing *</a></center></td>
            <td><center><a href="https://www.kantarmedia.com/global/privacy-statement" target="_blank" rel="noreferrer">Kantar *</a></center></td>
            <td><center><a href="https://weborama.com/e-privacy/" target="_blank" rel="noreferrer">Weborama *</a></center></td>
            <td></td>
          </tr>
          <tr>
            <td><center><a href="https://www.comscore.com/About-comScore/Privacy-Policy" target="_blank" rel="noreferrer">comScore *</a></center></td>
            <td><center><a href="https://www.refinedlabs.com/datenschutz-rfa" target="_blank" rel="noreferrer">Refined Labs *</a></center></td>
            <td><center><a href="https://www.kochava.com/support-privacy/" target="_blank" rel="noreferrer">Kochava *</a></center></td>
            <td><center><a href="https://www.whiteops.com/privacy" target="_blank" rel="noreferrer">White Ops *</a></center></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <br/>

      <strong>• Analytics</strong><br/><br/>
      The services contained in this section enable to monitor and analyze app traffic and can be used to keep track of some user behavior.
      <br/><br/>
      <ul>
        <li style={{ marginLeft: "40px" }}>
          <strong>Amplitude:</strong><br/><br/>
          Amplitude  is an analytics service provider. In order to understand Amplitude use of Data, consult <a href="https://amplitude.com/amplitude-security-and-privacy" target="_blank" rel="noreferrer">Amplitude Stance on Security and Privacy</a>.<br/><br/>
        </li>
      </ul>
      <ul>
        <li style={{ marginLeft: "40px" }}>
          <strong>Firebase:</strong><br/><br/>
          Firebase Analytics is a service which provides analytics features.  In order to understand Firebase Analytics use of data, consult <a href="https://firebase.google.com/support/privacy#:~:text=Firebase%20customers%20typically%20act%20as,service%20provider%22%20(CCPA)." target="_blank" rel="noreferrer">Privacy and Security in Firebase</a>.<br/><br/>
        </li>
      </ul>
      <br/>
      <p style={{fontSize:"24px", marginBottom:"40px"}}><strong>
      Notifications
      </strong></p>
      Notwithstanding anything else in this policy, we may work with partners who use mobile SDKs, including the OneSignal Messaging SDK, to passively collect information (collectively, 'SDK Information'), which generally helps us deliver personalized notifications. This data may also be used to identify you in a unique manner across other devices or browsers for the purposes of customizing ads or content.
      <br/><br/>
      Find out more one <a href="https://onesignal.com/privacy_policy" target="_blank" rel="noreferrer">HERE</a>.<br/><br/>
      <br/>
      <p style={{fontSize:"24px", marginBottom:"40px"}}><strong>
      Consumer Control & Opt-Out Options
      </strong></p>
      <ul>
        <li>
          <strong>a. Opting-out of OneSignal Push Notifications</strong><br/>
          You may in most cases opt out of receiving push notifications by going to your device "Settings" and clicking on "Notifications," and then changing those settings for some or all of the apps on your device. (Different device configurations, or updates to devices, may affect or change how these settings work.)
        </li>
        <br/>
        <li>
          <strong>b. Opting Out of "Cross-App" Advertising on Mobile Devices</strong><br/>
          You can opt out of having your mobile advertising identifiers used for certain types of interest-based (also called "cross-app") mobile behavioral advertising, by accessing the "Settings" on your Apple or Android mobile device, as follows: By opening the Google Settings app on your device, selecting Ads, and then selecting the option to opt-out of interest-based ads. (Different device configurations, or updates to devices, may affect or change how these settings work.)
        </li>
        <br/>
        <li>
          <strong>c. Within this app</strong><br/>
          Go to Settings --> Advertising --> Deny<br/><br/>
        </li>
      </ul>
      <br/>
      <p style={{fontSize:"24px", marginBottom:"40px"}}><strong>
      What are my opt-out rights?
      </strong></p>
      There are multiple opt-out options for users of this Application:<br/><br/>
      • Opt-out of all information collection by uninstalling the Application: You can stop all collection of information by the Application easily by uninstalling the Application. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.<br/><br/>
      • Opt-out from the use of information to serve targeted advertising by advertisers and/or third party network advertisers: you may at any time opt-out from further allowing us to have access to your location data. More information <a href="https://policies.google.com/technologies/partner-sites?hl=en" target="_blank" rel="noreferrer">HERE</a> and <a href="https://policies.oath.com/ie/en/oath/privacy/index.html" target="_blank" rel="noreferrer">HERE</a>.<br/><br/>
      <br/>
      <p style={{fontSize:"24px", marginBottom:"40px"}}><strong>
      Data Retention Policy, Managing Your Information
      </strong></p>
      We will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. If you'd like us to delete User Provided Data that you have provided via the Application, please contact us via email and we will respond in a reasonable time. Please note that some or all of the User Provided Data may be required in order for the Application to function properly.<br/><br/>
      <br/>
      <p style={{fontSize:"24px", marginBottom:"40px"}}><strong>
      Security
      </strong></p>
      We are concerned about safeguarding the confidentiality of your information. We provide physical, electronic, and procedural safeguards to protect information we process and maintain. For example, we limit access to this information to authorized employees and contractors who need to know that information in order to operate, develop or improve our Application. Please be aware that, although we endeavor provide reasonable security for information we process and maintain, no security system can prevent all potential security breaches.<br/><br/>
      <br/>
      <p style={{fontSize:"24px", marginBottom:"40px"}}><strong>
      Changes
      </strong></p>
      This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy and informing you via email. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.<br/><br/>
      <br/>
      <p style={{fontSize:"24px", marginBottom:"40px"}}><strong>
      Your Consent
      </strong></p>
      By using the Application, you are consenting to our processing of your information as set forth in this Privacy Policy now and as amended by us. "Processing," means using cookies on a computer/hand held device or using or touching information in any way, including, but not limited to, collecting, storing, deleting, using, combining and disclosing information.<br/><br/>
      <br/>
      <p style={{fontSize:"24px", marginBottom:"40px"}}><strong>
      Contact Us
      </strong></p>
      If you have any questions regarding privacy while using the Application, or have questions about our practices, please contact us via email to fede@moleqlargames.com.<br/><br/>
    </p>
    </div>
  );

export default Privacy;