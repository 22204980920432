import { useMemo } from 'react';
import { FullCenterContainer } from '../common/layouts';
import { Box, Heading, HeadingText, VStack, Footer, Header } from '../common/components';

const NotFound = () =>
{
    const items = useMemo(() => ['(o_o)', '(>_<)', '(˚Δ˚)', '(^Д^)', '(^-^*)', '(^_^)', '(·_·)'], []);
    const face = useMemo(() => items[Math.floor(Math.random() * items.length)], [items])
    return (
        <FullCenterContainer
        header={<Header />}
        footer={<Footer />}>
            <Box w="100%" textAlign="center">
                <VStack spacing="20px">
                    <Heading size="4xl">{face}</Heading>
                    <HeadingText size="lg">Oops! Página no encontrada.</HeadingText>
                </VStack>
            </Box>
        </FullCenterContainer>
    );
}

export default NotFound;