import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

import MainPage from './pages/main';
import GamePage from './pages/game';
import PrivacyPage from './pages/privacy';
import CookiesPage from './pages/cookies';
import NotFoundPage from './pages/NotFound';

const App = () =>
(
    <Routes>
        <Route index element={<MainPage />} />
        <Route path="/game" element={<GamePage />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
        <Route path="/cookies-policy" element={<CookiesPage />} />
        <Route path="*" element={<NotFoundPage />} />
    </Routes>
);

export default App;