import { motion } from "framer-motion";
import { React, useState } from "react";

import "./Game.css";

import female_idle from "../assets/img/cpu_idle.svg";
import female_paper from "../assets/img/cpu_paper.svg";
import female_rock from "../assets/img/cpu_rock.svg";
import female_scissors from "../assets/img/cpu_scissors.svg";
import male_paper from "../assets/img/person_paper.svg";
import male_rock from "../assets/img/person_rock.svg";
import male_scissors from "../assets/img/person_scissors.svg";
import male_idle from "../assets/img/person_idle.svg";
import rock_icon from "../assets/img/rock_icon.svg";
import paper_icon from "../assets/img/paper_icon.svg";
import scissors_icon from "../assets/img/scissors_icon.svg";
import random_icon from "../assets/img/random_icon.svg";
import restart from "../assets/img/restart.svg";
import result_cpu from "../assets/img/person_avatar.svg";
import result_user from "../assets/img/cpu_avatar.svg";
import user_hp_avatar from "../assets/img/person_miniavatar.svg";
import cpu_hp_avatar from "../assets/img/cpu_miniavatar.svg";
import rock_sound from "../assets/sound/rock.mp3";
import paper_sound from "../assets/sound/slap.mp3";
import scissors_sound from "../assets/sound/scissors.mp3";
import start_sound from "../assets/sound/start.mp3";
import confetti_sound from "../assets/sound/confetti.mp3";
import lose_sound from "../assets/sound/lose.mp3";

const Game = () => 
{
	const [userChoice, setUserChoice] = useState(male_idle);
	const [computerChoice, setComputerChoice] = useState(female_idle);
	const [userPoints, setUserPoints] = useState(0);
	const [computerPoints, setComputerPoints] = useState(0);
	const [maleImg, setMaleImg] = useState(male_idle);
	const [femaleImg, setFemaleImg] = useState(female_idle);

	const [result, setResult] = useState("Let's see who wins");
	const [gameOver, setGameOver] = useState(false);

	const choices = ["rock", "paper", "scissors"];

	let rock_sfx = new Audio(rock_sound);
	let paper_sfx = new Audio(paper_sound);
	let scissors_sfx = new Audio(scissors_sound);
	let start_sfx = new Audio(start_sound);
	let confetti_sfx = new Audio(confetti_sound);
	let lose_sfx = new Audio(lose_sound);

	const handleClick = (playerChoice) => 
	{
		if (gameOver) return;

		setUserChoice(playerChoice);
		const cpuChoice = generateComputerChoice();
		switch (playerChoice) {
			case 'scissors':
				setMaleImg(male_scissors);
				if (cpuChoice === "rock") rock_sfx.play();
				else if (cpuChoice === "paper") scissors_sfx.play();
				break;
			case 'rock':
				setMaleImg(male_rock);
				if (cpuChoice === "paper") paper_sfx.play();
				else if (cpuChoice === "scissors") rock_sfx.play();
				break;
			case 'paper':
				setMaleImg(male_paper);
				if (cpuChoice === "scissors") scissors_sfx.play();
				else if (cpuChoice === "rock") paper_sfx.play();
				break;
			default:
		};
		handleResult({ playerChoice, cpuChoice });
	};

  	const randomClick = () => 
	{
    	const randomClick = generateRandomChoice();
		handleClick(randomClick);
  	};

	const generateRandomChoice = () => choices[Math.floor(Math.random() * choices.length)];
	const generateComputerChoice = () =>
	{
		const choice = generateRandomChoice();
		setComputerChoice(choice);
		switch (choice) {
			case 'scissors': setFemaleImg(female_scissors); break;
			case 'rock': setFemaleImg(female_rock); break;
			case 'paper': setFemaleImg(female_paper); break;
			default: setFemaleImg(female_idle); break;
		}
		return choice;
	};

	const handleReset = () => 
	{
		start_sfx.play();
		setGameOver(false);
		setUserPoints(0);
		setComputerPoints(0);
		setMaleImg(male_idle);
		setFemaleImg(female_idle);
	};

  	const handleResult = ({ playerChoice, cpuChoice }) => 
	{
		if (userPoints >= 5 || computerPoints >= 5) return;

    	const comboMoves = playerChoice + cpuChoice;
    	if (
        	comboMoves === "scissorspaper" ||
        	comboMoves === "rockscissors" ||
        	comboMoves === "paperrock"
      	) {
        	const updatedUserPoints = userPoints + 1;
        	setUserPoints(updatedUserPoints);
        	if (updatedUserPoints === 5) {
				setResult("You Win");
				const gameOff = true;
				setTimeout(() => {
					setGameOver(gameOff);
					confetti_sfx.play();
				}, 1000);
       		}
      	}

      	if (
			comboMoves === "paperscissors" ||
			comboMoves === "scissorsrock" ||
			comboMoves === "rockpaper"
		) {
        	const updatedComputerPoints = computerPoints + 1;
        	setComputerPoints(updatedComputerPoints);
			if (updatedComputerPoints === 5) {
          		setResult("You Lose");
          		const gameOff = true;
          		setTimeout(() => {
            		setGameOver(gameOff);
            		lose_sfx.play();
          	}, 1000);
        	}
      	}
  	};

  	return (
        <div className="App">
          {!gameOver && (
            <>
              <div className="game">
                <div className="top">
                  <motion.img
                    key={computerChoice}
                    src={femaleImg}
                    alt=""
                    transition={{
                      ease: "easeOut",
                      duration: 0.5,
                    }}
                    initial={{ y: -200 }}
                    animate={{ y: -50 }}
                  />{" "}
                </div>
                <div className="bottom">
                  <motion.img
                    src={maleImg}
                    key={userChoice}
                    alt=""
                    transition={{ ease: "easeOut", duration: 0.5 }}
                    initial={{ y: 200 }}
                    animate={{ y: 50 }}
                  />
                </div>
                <div className="ui">
                  <div className="ui-box">
                    <img
                      src={rock_icon}
                      alt=""
                      className="rock_icon"
                      onClick={() => handleClick(choices[0])}
                    />
                    <img
                      src={paper_icon}
                      alt=""
                      className="paper_icon"
                      onClick={() => handleClick(choices[1])}
                    />
                    <img
                      src={scissors_icon}
                      alt=""
                      className="scissors_icon"
                      onClick={() => handleClick(choices[2])}
                    />
                    <img
                      src={random_icon}
                      alt=""
                      className="random_icon"
                      onClick={() => randomClick()}
                    />
                  </div>
                </div>
              </div>
              <div className="score">
                {gameOver && <p>{result}</p>}
                <div className="hp-box-user">
                  <div className="hp-box-inner-user">
                    <progress
                      className="user-hp"
                      value={5 - computerPoints}
                      max="5"
                    ></progress>
                    <motion.img
                      src={user_hp_avatar}
                      className="user_hp_avatar"
                      alt=""
                      key={computerPoints}
                      animate={{
                        rotate: [0, 0, 20, 20, 0, 20, 20, 0],
                      }}
                      transition={{ duration: 0.5 }}
                    />
                  </div>
                </div>
                <div className="hp-box-cpu">
                  <div className="hp-box-inner-user">
                    <progress
                      className="user-hp cpu"
                      value={5 - userPoints}
                      max="5"
                    ></progress>
                    <motion.img
                      src={cpu_hp_avatar}
                      className="cpu_hp_avatar"
                      alt=""
                      key={userPoints}
                      animate={{
                        rotate: [0, 0, 20, 20, 0, 20, 20, 0],
                      }}
                      transition={{ duration: 0.5 }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {gameOver && (
            <motion.div
              className="result"
              animate={{ scale: 1.3 }}
              transition={{
                duration: 0.5,
              }}
            >
              
              <motion.img
                src={result === "You Lose" ? result_user : result_cpu}
                alt=""
                animate={{
                  scale: [1, 1.5, 1.5, 1, 1],
                  rotate: [0, 0, 270, 270, 0],
                }}
                transition={{ duration: 1 }}
              />
              <p className="result-msg">{result}</p>
              <p className="result-score">
                {computerPoints} - {userPoints}
              </p>
              <motion.img
                src={restart}
                alt=""
                onClick={handleReset}
                animate={{ scale: [1, 1.2, 1.2, 1, 1] }}
                transition={{ repeat: Infinity, duration: 1.5 }}
              />
            </motion.div>
          )}
        </div>
  	);
};

export default Game;
