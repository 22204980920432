import UILogo from './Logo';
import UIHeadingText from './HeadingText';
import UIHeader from './Header';
import UIFooter from './Footer';
import UILinkButton from './LinkButton';
import {
    Box as ChakraBox,
    Flex as ChakraFlex,
    Image as ChakraImage,
    Text as ChakraText,
    Heading as ChakraHeading,
    Button as ChakraButton,
    SimpleGrid as ChakraSimpleGrid,
    VStack as ChakraVStack,
    HStack as ChakraHStack,
    Stack as ChakraStack,
    StackDivider as ChakraStackDivider,
    Center as ChakraCenter,
    Spinner as ChakraSpinner,
    Spacer as ChakraSpacer,
    Link as ChakraLink,
    ButtonGroup as ChakraButtonGroup,
    IconButton as ChakraIconButton,
    Slider as ChakraSlider,
    SliderTrack as ChakraSliderTrack,
    SliderFilledTrack as ChakraSliderFilledTrack,
    FormControl as ChakraFormControl,
    FormLabel as ChakraFormLabel,
    Input as ChakraInput,
    InputGroup as ChakraInputGroup,
    InputLeftAddon as ChakraInputLeftAddon,
    InputLeftElement as ChakraInputLeftElement,
    InputRightElement as ChakraInputRightElement,
    FormHelperText as ChakraFormHelperText,
    FormErrorMessage as ChakraFormErrorMessage,
    Textarea as ChakraTextarea,
    Tag as ChakraTag,
    TagLabel as ChakraTagLabel,
    Stat as ChakraStat,
    StatLabel as ChakraStatLabel,
    StatNumber as ChakraStatNumber,
    Wrap as ChakraWrap,
    WrapItem as ChakraWrapItem,
    TableContainer as ChakraTableContainer,
    Table as ChakraTable,
    TableCaption as ChakraTableCaption,
    Thead as ChakraThead,
    Tbody as ChakraTbody,
    Tfoot as ChakraTfoot,
    Tr as ChakraTr,
    Th as ChakraTh,
    Td as ChakraTd,
    Badge as ChakraBadge,
    Select as ChakraSelect,
    Square as ChakraSquare,
    Tooltip as ChakraTooltip,
    Tabs as ChakraTabs,
    TabList as ChakraTabList,
    TabPanels as ChakraTabPanels,
    TabPanel as ChakraPanel,
    Avatar as ChakraAvatar,
    AvatarGroup as ChakraAvatarGroup,
    Modal as ChakraModal,
    ModalOverlay as ChakraModalOverlay,
    ModalContent as ChakraModalContent,
    ModalCloseButton as ChakraModalCloseButton,
    Progress as ChakraProgress,
    CircularProgress as ChakraCircularProgress,
    Switch as ChakraSwitch,
    Divider as ChakraDivider,
    Circle as ChakraCircle,
    Checkbox as ChakraCheckbox,
    Menu as ChakraMenu,
    MenuButton as ChakraMenuButton,
    MenuItem as ChakraMenuItem,
    MenuList as ChakraMenuList
} from '@chakra-ui/react';
import {
    Breakpoint as SocksBreakpoint,
    BreakpointProvider as SocksBreakpointProvider
} from 'react-socks';

// Chakra UI components
export const Box = ChakraBox;
export const Flex = ChakraFlex;
export const Image = ChakraImage;
export const Text = ChakraText;
export const Heading = ChakraHeading;
export const Button = ChakraButton;
export const SimpleGrid = ChakraSimpleGrid;
export const VStack = ChakraVStack;
export const HStack = ChakraHStack;
export const Stack = ChakraStack;
export const StackDivider = ChakraStackDivider;
export const Center = ChakraCenter;
export const Spinner = ChakraSpinner;
export const Spacer = ChakraSpacer;
export const Link = ChakraLink;
export const ButtonGroup = ChakraButtonGroup;
export const IconButton = ChakraIconButton;
export const Slider = ChakraSlider;
export const SliderTrack = ChakraSliderTrack;
export const SliderFilledTrack = ChakraSliderFilledTrack;
export const FormControl = ChakraFormControl;
export const FormLabel = ChakraFormLabel;
export const FormInput = ChakraInput;
export const FormInputGroup = ChakraInputGroup;
export const FormInputLeftElement = ChakraInputLeftElement;
export const FormInputRightElement = ChakraInputRightElement;
export const FormInputLeftAddon = ChakraInputLeftAddon;
export const FormHelperText = ChakraFormHelperText;
export const FormErrorMessage = ChakraFormErrorMessage;
export const Textarea = ChakraTextarea;
export const Tag = ChakraTag;
export const TagLabel = ChakraTagLabel;
export const Stat = ChakraStat;
export const StatLabel = ChakraStatLabel;
export const StatNumber = ChakraStatNumber;
export const Wrap = ChakraWrap;
export const WrapItem = ChakraWrapItem;
export const TableContainer = ChakraTableContainer;
export const Table = ChakraTable;
export const TableCaption = ChakraTableCaption;
export const Thead = ChakraThead;
export const Tbody = ChakraTbody;
export const Tfoot = ChakraTfoot;
export const Tr = ChakraTr;
export const Th = ChakraTh;
export const Td = ChakraTd;
export const Badge = ChakraBadge;
export const Select = ChakraSelect;
export const Square = ChakraSquare;
export const Tooltip = ChakraTooltip;
export const Tabs = ChakraTabs;
export const TabList = ChakraTabList;
export const TabPanels = ChakraTabPanels;
export const TabPanel = ChakraPanel;
export const Avatar = ChakraAvatar;
export const AvatarGroup = ChakraAvatarGroup;
export const Modal = ChakraModal;
export const ModalOverlay = ChakraModalOverlay;
export const ModalContent = ChakraModalContent;
export const ModalCloseButton = ChakraModalCloseButton;
export const Progress = ChakraProgress;
export const CircularProgress = ChakraCircularProgress;
export const Switch = ChakraSwitch;
export const Divider = ChakraDivider;
export const Circle = ChakraCircle;
export const Checkbox = ChakraCheckbox;
export const Menu = ChakraMenu;
export const MenuButton = ChakraMenuButton;
export const MenuItem = ChakraMenuItem;
export const MenuList = ChakraMenuList;
// Socks UI components
export const Breakpoint = SocksBreakpoint;
export const BreakpointProvider = SocksBreakpointProvider;
// Custom UI components
export const Logo = UILogo;
export const HeadingText = UIHeadingText;
export const Header = UIHeader;
export const Footer = UIFooter;
export const LinkButton = UILinkButton;