import { Box, Flex, Logo, Text, LinkButton, Breakpoint, BreakpointProvider, VStack, HStack, Link } from './index';
import { Link as ReactRouter } from 'react-router-dom';
import links from '../links';

const Footer = ({ full }) =>
(
    <BreakpointProvider>
        <Breakpoint small down>
            <VStack mb="20px">
                { full && <LinkButton to={links.blog} isExternal>Blog</LinkButton> }
                <LinkButton to={links.privacypolicy}>Política de privacidad</LinkButton>
                <LinkButton to={links.cookiepolicy}>Política de cookies</LinkButton>
                <Link as={ReactRouter} to={links.egg}>
                    <Logo size="40px" />
                </Link>
                <Text fontSize="14px" fontWeight="400" color="#98A2B3">© 2022 moleQlar games</Text>
            </VStack>
        </Breakpoint>
        <Breakpoint medium up>
            <Box h="60px" px="20px" pb="20px" width="100%">
                <Flex flexDirection="row" maxW="1216px" mx="auto" h="100%">
                    <Flex align="center" w="180px">
                        <Link as={ReactRouter} to={links.egg}>
                            <Logo size="40px" />
                        </Link>
                    </Flex>
                    <Flex flex="1" align="center" justify="center">
                        <HStack spacing="18px">
                            { full && <LinkButton to={links.blog} isExternal>Blog</LinkButton> }
                            <LinkButton to={links.privacypolicy}>Política de privacidad</LinkButton>
                            <LinkButton to={links.cookiepolicy}>Política de cookies</LinkButton>
                        </HStack>
                    </Flex>
                    <Flex align="center" justify="center" w="180px">
                        <Text fontSize="14px" fontWeight="400" color="#98A2B3">© 2022 moleQlar games</Text>
                    </Flex>
                </Flex>
            </Box>
        </Breakpoint>
      </BreakpointProvider>
);

export default Footer;